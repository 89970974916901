import type {Page} from '@PosterWhiteboard/page/page.class';
import {isUserPremium} from '@Libraries/user.library';
import {FabricText, Group, Rect, Shadow} from '@postermywall/fabricjs-2';

interface WatermarkOpts {
  mode?: PAGE_WATERMARK_MODE;
  enlargeWatermark?: boolean;
  showPMW?: boolean;
}

export enum PAGE_WATERMARK_MODE {
  NONE = 'none',
  DEFAULT = 'normal',
  LARGE = 'large', // the watermark is rendered in large font across the center of the poster,
  VIDEO_LARGE = 'videolarge', // the watermark is rendered in large near the bottom with background and mentioned that this video is created using postermywall
}

const DEFAULT_WATERMARK_FONT_SIZE = 11;

export class PageWatermark {
  public page: Page;
  private videoLargeWatermarkFabricObject?: Group;
  private watermarkFabricObject?: FabricText;
  private watermarkAttrFabricObject?: FabricText;

  constructor(page: Page) {
    this.page = page;
  }

  public refreshWatermark = (): void => {
    if (this.page.poster.isHighRes) {
      this.setWatermark({
        mode: PAGE_WATERMARK_MODE.NONE,
      });
    } else {
      this.page.pageWatermark.setWatermark({
        mode: this.getDefaultMode(),
        showPMW: false,
      });
    }
  };

  private getDefaultMode = (): PAGE_WATERMARK_MODE => {
    if (this.page.poster.mode.isWebpage()) {
      return this.page.poster.isVideo() ? PAGE_WATERMARK_MODE.VIDEO_LARGE : PAGE_WATERMARK_MODE.DEFAULT;
    }

    return PAGE_WATERMARK_MODE.DEFAULT;
  };

  public setWatermark({mode = PAGE_WATERMARK_MODE.DEFAULT, enlargeWatermark = false, showPMW = true}: WatermarkOpts): void {
    if (mode === PAGE_WATERMARK_MODE.VIDEO_LARGE) {
      this.showLargeVideoWatermark();
      return;
    }

    let text = '';
    const showPosterMyWall = isUserPremium() ? false : showPMW;
    let attrText = '';
    let linesCount = 1;
    const smallFontSize = Math.max(Math.floor(this.page.poster.width / 54), DEFAULT_WATERMARK_FONT_SIZE);
    let fontSize = smallFontSize;
    let attrFontSize = smallFontSize;
    let textAlign = 'left';
    let topOffset;
    let attrTopOffset;
    const defaults = {
      fill: '#000000',
      opacity: 0.7,
      fontFamily: 'sans-serif',
      selectable: false,
      hasControls: false,
      hasBorders: false,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
    };

    if (!this.page.poster.isHighRes && mode !== PAGE_WATERMARK_MODE.NONE) {
      /**
       * Can have up to 3 lines in watermark at design time:
       * 1. PosterMyWall.com
       * 2. Premium Content
       * 3. Attribution name
       */
      if (showPosterMyWall) {
        if (enlargeWatermark) {
          fontSize *= 2.2;
        }
        text = 'Made with PosterMyWall.com';
      } else {
        text = '';
        linesCount -= 1;
      }

      if (this.page.poster.creator != null) {
        if (this.page.poster.creator.watermark !== undefined) {
          text = this.page.poster.creator.watermark;
        }
      } else if (window.PMW.currentUserOnPageLoad?.watermark) {
        text = window.PMW.currentUserOnPageLoad.watermark;
      }

      if (this.showGettyWatermark()) {
        if (text.length > 0) {
          text += '\nGetty Stock';
        } else {
          text = 'Getty Stock';
        }
        linesCount += 1;
      }

      if (this.page.background.details.isImage() && this.page.background.details.imageBackgroundItem.requiresAttribution()) {
        attrText = this.getAttribution(this.page.background.details.imageBackgroundItem.attributionName);
      }
    }

    let watermarkChangeFactor = this.page.poster.height / 30;
    let attrChangeFactor = this.page.poster.height / 45;

    if (this.page.poster.width < this.page.poster.height) {
      watermarkChangeFactor = this.page.poster.width / 30;
      attrChangeFactor = this.page.poster.width / 45;
    }

    if (mode === PAGE_WATERMARK_MODE.LARGE) {
      fontSize = smallFontSize + watermarkChangeFactor;
      attrFontSize = smallFontSize + attrChangeFactor;
      textAlign = 'center';
    }

    // have advert watermark leave space after itself for the attribution watermark
    if (attrText.length > 0) {
      linesCount += 1;
    }
    let extraTranslation = this.page.poster.height / 70;
    if (textAlign === 'center') {
      extraTranslation = this.page.poster.height / 10;
    }
    topOffset = Math.ceil(this.page.poster.height - (linesCount * fontSize * (linesCount > 1 ? 1.25 : 1) + extraTranslation));
    attrTopOffset = this.page.poster.height - (fontSize + extraTranslation);

    if (mode === PAGE_WATERMARK_MODE.LARGE) {
      topOffset = Math.ceil(this.page.poster.height / 2 - (linesCount * fontSize * (linesCount > 1 ? 1.25 : 1)) / 2);
      attrTopOffset = topOffset + (linesCount - 1) * fontSize * (linesCount > 1 ? 1.25 : 1);
    }

    if (!this.watermarkFabricObject) {
      // only initialize if we need to show a watermark
      if (text.length > 0) {
        const advertOptions = {
          top: topOffset,
          left: 10,
          fontSize,
          lineHeight: 1.2,
          textAlign,
          padding: -5,
          ...defaults,
        };

        this.watermarkFabricObject = new FabricText(text, advertOptions);
        this.watermarkFabricObject.set(
          'shadow',
          new Shadow({
            color: '#fff',
            blur: 2,
            offsetX: 0,
            offsetY: 0,
          })
        );

        this.page.fabricCanvas.add(this.watermarkFabricObject);
      }
    } else {
      this.watermarkFabricObject.set({
        text,
        fontSize,
        top: topOffset,
        left: 10,
        textAlign,
      });
    }

    if (!this.watermarkAttrFabricObject) {
      if (attrText.length > 0) {
        const attrOptions = {
          top: attrTopOffset,
          left: 10,
          fontSize: attrFontSize,
          lineHeight: 1.2,
          textAlign,
          ...defaults,
        };

        this.watermarkAttrFabricObject = new FabricText(attrText, attrOptions);
        this.page.fabricCanvas.add(this.watermarkAttrFabricObject);
      }
    } else {
      this.watermarkAttrFabricObject.set({
        text: attrText,
        fontSize: attrFontSize,
        top: attrTopOffset,
        left: 10,
        textAlign,
      });
    }

    if (textAlign === 'center') {
      if (this.watermarkFabricObject) {
        this.watermarkFabricObject.set({
          // in case the watermark is wider than the poster, have it start from 10 pixels
          left: Math.max(Math.round((this.page.poster.width - (this.watermarkFabricObject.width ?? 0)) / 2), 10),
        });
      }
      if (this.watermarkAttrFabricObject) {
        this.watermarkAttrFabricObject.set({
          // in case the watermark is wider than the poster, have it start from 10 pixels
          left: Math.max(Math.round((this.page.poster.width - (this.watermarkAttrFabricObject.width ?? 0)) / 2), 10),
        });
      }
    }

    this.page.fabricCanvas.requestRenderAll();
  }

  showLargeVideoWatermark = (): void => {
    this.watermarkFabricObject?.set({
      text: '',
    });
    this.watermarkAttrFabricObject?.set({
      text: '',
    });

    if (!this.videoLargeWatermarkFabricObject) {
      const rect = new Rect({
        left: 0,
        top: 0,
        opacity: 0.4,
        fill: '#5e5e5e',
        originX: 'center',
        originY: 'center',
        width: this.page.getNonScaledCanvasScaleddWidth(),
        height: this.page.getNonScaledCanvasScaleddHeight() / 8,
      });
      const text = new FabricText(window.i18next.t('pmwjs_video_watermark_message'), {
        fontFamily: '"Nunito Sans", Helvetica, Arial, sans-serif',
        textAlign: 'center',
        fontWeight: 'bold',
        fill: '#ffffff',
        originX: 'center',
        originY: 'center',
      });

      text.scaleToHeight(rect.getScaledHeight() - rect.getScaledHeight() / 10);

      const bottomPadding = this.page.getNonScaledCanvasScaleddHeight() / 17;
      this.videoLargeWatermarkFabricObject = new Group([rect, text], {
        left: 0,
        top: this.page.getNonScaledCanvasScaleddHeight() - rect.getScaledHeight() - bottomPadding,
        selectable: false,
        evented: false,
      });
      this.page.fabricCanvas.add(this.videoLargeWatermarkFabricObject);
    }
  };

  private getAttribution(name?: string): string {
    return name ? `Background by ${name}` : '';
  }

  private showGettyWatermark(): boolean {
    return this.page.validatePremiumUserAccess();
  }
}
