import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {FilterOrders, ItemEffects} from '@PosterWhiteboard/classes/item-effects.class';
import {ImageItemSource, ScreenTier} from '@Libraries/image-item.library';
import {hasTransparency} from '@Utils/image.util';
import {GROWL_TYPE, showMessageGrowl} from '@Components/message-growl';
import type {
  CopyableItemStylesAndProperties,
  ImageBackgroundItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForImageBackgroundItem} from '@PosterWhiteboard/libraries/paste-styles.library';

const TRANSPARENCY_DISABLED = 'disabled';

export const TransparencyStates = {
  DISABLED: TRANSPARENCY_DISABLED,
  ON: 'on',
  OFF: 'off',
};

const BackgroundImageSources = {
  BG_UPLOAD: 'background_upload',
  UNKNOWN: 'unknown',
};

export interface ImageBackgroundItemObject extends ImageItemObject {
  transparency: string;
  attributionName?: string;
  attributionURL?: string;
  licenseName?: string;
  licenseURL?: string;
  displayAttribution: boolean;
  idUser: number | null;
}

export class ImageBackgroundItem extends ImageItem {
  public gitype = ITEM_TYPE.IMAGEBACKGROUND;
  /*
      This transparency deals only with image backgrounds. Transparency of a background is disabled if it doesn't have any transparenct areas in it causing the button
      to toggle transparency be hidden. In case of transparent areas in the image the value of this variable depends on the state of toggle switch user has selected
    */
  public transparency = TRANSPARENCY_DISABLED;
  public attributionName?: string;
  public attributionURL?: string;
  public licenseName?: string;
  public licenseURL?: string;
  public displayAttribution = true;
  public idUser: number | null = null;

  public constructor(page: Page) {
    super(page);
    this.effects = new ItemEffects(this, FilterOrders.IMAGEBACKGROUND);
  }

  public toObject(): ImageBackgroundItemObject {
    return {
      ...super.toObject(),
      transparency: this.transparency,
      attributionName: this.attributionName,
      attributionURL: this.attributionURL,
      licenseName: this.licenseName,
      licenseURL: this.licenseURL,
      displayAttribution: this.displayAttribution,
      idUser: this.idUser,
    };
  }

  protected getScreenUrl(): string {
    if (this.hashedFilename && this.fileExtension) {
      return this.getUrlForImageItemScreenTier(ScreenTier.BIG);
    }
    if (this.uploadingImageData?.dataUrl) {
      return this.uploadingImageData.dataUrl;
    }
    throw new Error(`Neither hashedFilename+Extension nor dataUrl is present`);
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      scaleX: (this.page.poster.width + 1) / this.fabricObject.width,
      scaleY: (this.page.poster.height + 1) / this.fabricObject.height,
      x: -1,
      y: -1,
    });
  }

  protected isCurrentItemPageBackground(): boolean {
    return this.page.fabricCanvas.backgroundImage === this.fabricObject;
  }

  protected async reInitFabricObject(): Promise<void> {
    await this.beforeInitFabricObject();
    const isCurrentItemPageBackground = this.isCurrentItemPageBackground();
    await this.initFabricObject();
    if (isCurrentItemPageBackground) {
      this.page.setBackgroundImage(this.fabricObject);
    }
  }

  protected override onItemInitialized(): Promise<void> {
    return new Promise((resolve) => {
      this.hasTransparency = this.hashedFilename && this.isJPG() ? false : hasTransparency(this.imageElement);
      this.verifyTransparencyFlags();
      resolve();
    });
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
    } as ImageBackgroundItemStyles;
  }
  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForImageBackgroundItem(copiedProperties, this);
  }

  public async initFabricObject(): Promise<void> {
    await super.initFabricObject();
    this.hasTransparency = this.hashedFilename && this.isJPG() ? false : hasTransparency(this.imageElement);
    this.verifyTransparencyFlags();
  }

  protected verifyTransparencyFlags(): void {
    if (this.hasTransparency === false && !this.isBackgroundTransparencyDisabled()) {
      this.transparency = TransparencyStates.DISABLED;
    } else if (this.page.poster.isInitialized && this.hasTransparency) {
      if (this.isBackgroundTransparencyDisabled() && !this.page.poster.isVideo()) {
        this.transparency = TransparencyStates.ON;
      } else if (this.isBackgroundImageTransparencyOn() && this.page.poster.isVideo()) {
        this.transparency = TransparencyStates.OFF;
      }
    }
  }

  public isBackgroundImageTransparencyOn(): boolean {
    return this.transparency === TransparencyStates.ON;
  }

  public isBackgroundImageTransparencyOff(): boolean {
    return this.transparency === TransparencyStates.OFF;
  }

  public isBackgroundTransparencyDisabled(): boolean {
    return this.transparency === TransparencyStates.DISABLED;
  }

  protected isSourceUploaded(): boolean {
    return this.imageSource === BackgroundImageSources.BG_UPLOAD;
  }

  protected isSourceUnknown(): boolean {
    return this.imageSource === BackgroundImageSources.UNKNOWN;
  }

  public requiresAttribution(): boolean {
    return !!this.attributionName && !!this.licenseName && this.imageSource !== ImageItemSource.GETTY && this.imageSource !== ImageItemSource.STORYBLOCKS;
  }

  public onUploadingImageFailed(e: Error): void {
    console.error(e);
    showMessageGrowl({
      text: window.i18next.t('pmwjs_image_upload_error'),
      type: GROWL_TYPE.DANGER,
    });
    this.page.background.updateBackgroundToDefaultState();
  }

  protected async beforeInitFabricObject(): Promise<void> {
    await super.beforeInitFabricObject();
    this.loading.removeLoading();
  }
}
