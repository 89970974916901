import type {ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import {GlobalPosterEditorJqueryElement} from '@Components/poster-editor/poster-editor.types';
import {isMobile} from 'react-device-detect';
import {updateCanvasScroll, updateVerticalScrollbarState} from '@Components/poster-editor/poster-editor-reducer';
import styles from './poster-vertical-scroll.module.scss';
import {useAppDispatch, useAppSelector} from '@/hooks';

// Leave extra margin on bottom for mobile devices so that the keyboard when opened doesn't hide the canvas
const MARGIN_BOTTOM_MOBILE = 600;
export const MARGIN_BOTTOM_DEFAULT = 20;
const SCROLL_BAR_WDITH = '18px';

export function PosterVerticalScroll(): ReactElement | null {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scale = useAppSelector((state) => {
    return state.posterEditor?.posterObject?.scaling.scale;
  });

  const posterHeight = useAppSelector((state) => {
    return state.posterEditor?.posterObject?.height;
  });

  const isMobileVariant = useAppSelector((state) => {
    return state.posterEditor.isMobileVariant;
  });

  const newCanvasTotalHeight = posterHeight !== undefined && scale !== undefined ? posterHeight * scale : 0;
  const newCanvasTotalHeightWithScrollMargin = newCanvasTotalHeight + getMarginBottom();
  const whiteboardContainer = window.document.getElementById('poster-whiteboard-container');
  const areScrollBarsVisible = whiteboardContainer ? newCanvasTotalHeight > whiteboardContainer.clientHeight : false;
  const newCanvasVisibleHeight = areScrollBarsVisible && whiteboardContainer ? whiteboardContainer.clientHeight : newCanvasTotalHeight;
  const page = window.posterEditor?.whiteboard?.getCurrentPage();
  const canvas = page?.fabricCanvas;
  const dispatch = useAppDispatch();

  useEffect((): void => {
    dispatch(updateVerticalScrollbarState(areScrollBarsVisible));
  }, [areScrollBarsVisible]);

  const refreshVisibleCanvas = (): void => {
    if (!scrollContainerRef.current || !whiteboardContainer || !newCanvasTotalHeight) {
      return;
    }

    const scrollableHeight = scrollContainerRef.current.scrollHeight - scrollContainerRef.current.clientHeight;

    const scrollableHeightForMargin = getMarginBottom();
    const scrollableHeightForCanvas = Math.max(0, scrollableHeight - scrollableHeightForMargin);

    const scrollPercentageForCanvas = scrollableHeightForCanvas ? Math.min(1, scrollContainerRef.current.scrollTop / scrollableHeightForCanvas) : 0;
    const scrollAmountForMargin = Math.max(0, scrollContainerRef.current.scrollTop - scrollableHeightForCanvas);
    const scrollPercentageForMargin = scrollableHeightForMargin ? Math.min(1, scrollAmountForMargin / scrollableHeightForMargin) : 0;

    if (canvas) {
      canvas.viewportTransform[5] = -(scrollPercentageForCanvas * (newCanvasTotalHeight - newCanvasVisibleHeight));
      canvas.setViewportTransform(canvas.viewportTransform);
      canvas.requestRenderAll();

      window.posterEditor?.elements[GlobalPosterEditorJqueryElement.WHITEBOARD]?.css('top', `-${scrollPercentageForMargin * getMarginBottom()}px`);

      if (page) {
        dispatch(
          updateCanvasScroll({
            verticalScroll: canvas.viewportTransform[5],
          })
        );
      }
    }
  };

  const onScroll = (): void => {
    if (page && !page.canvasPanZoom.isTouchGestureBeingApplied) {
      refreshVisibleCanvas();
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current && window.posterEditor) {
      window.posterEditor.elements[GlobalPosterEditorJqueryElement.POSTER_VERTICAL_SCROLL] = $(scrollContainerRef.current);
    }
  }, []);

  useEffect(() => {
    scrollContainerRef.current?.addEventListener('scroll', onScroll);

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', onScroll);
    };
  }, [newCanvasTotalHeight, newCanvasTotalHeightWithScrollMargin, newCanvasVisibleHeight, canvas, whiteboardContainer, scrollContainerRef.current]);

  useEffect(() => {
    refreshVisibleCanvas();
  }, [scale]);

  return (
    <div
      style={{width: SCROLL_BAR_WDITH}}
      className={`${styles.scrollContainer} ${isMobileVariant ? styles.hideScrollbarContent : ''} ${areScrollBarsVisible ? '' : styles.disablePointer}`}
      ref={scrollContainerRef}
    >
      <div style={{height: `${newCanvasTotalHeightWithScrollMargin}px`, width: SCROLL_BAR_WDITH}} />
    </div>
  );
}

export const scrollPosterVerticalWithDelta = (deltaY: number, animate = false): void => {
  const htmlElement = window.posterEditor?.elements[GlobalPosterEditorJqueryElement.POSTER_VERTICAL_SCROLL]?.get(0);

  if (htmlElement) {
    const newScrollTop = Math.max(0, htmlElement.scrollTop + deltaY);
    if (animate) {
      animateScrollTop(htmlElement, newScrollTop, 100);
    } else {
      htmlElement.scrollTop = newScrollTop;
    }
  }

  function animateScrollTop(element: HTMLElement, targetScrollTop: number, duration: number): void {
    const startTime = Date.now();
    const startScrollTop = element.scrollTop;
    const distance = targetScrollTop - startScrollTop;

    const easeOutSine = (t: number): number => {
      return Math.sin((t * Math.PI) / 2);
    };

    const step = (): void => {
      const currentTime = Date.now();
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const easedProgress = easeOutSine(progress);

      element.scrollTop = startScrollTop + distance * easedProgress;

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }
};

export const getMarginBottom = (): number => {
  return isMobile ? MARGIN_BOTTOM_MOBILE : MARGIN_BOTTOM_DEFAULT;
};
