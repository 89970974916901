import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import {PAGE_WATERMARK_MODE} from '@PosterWhiteboard/page/page-watermark.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import {secondsToMicroSeconds} from '@Utils/math.util';
import {PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';

interface GenerateFrameForPosterTimeResponse {
  imageData: string;
  logData: Record<string, number>;
}

export class PosterVideoGeneration {
  private poster: Poster;

  constructor(poster: Poster) {
    this.poster = poster;
  }

  public initGenerateFramesForVideoPoster(): void {
    if (this.poster.mode.details.type !== PosterModeType.GENERATE) {
      throw new Error('Incorrect poster mode.It should be generate.');
    }

    const page = this.poster.getCurrentPage();
    const posterHasAnimation = page.introAnimation.hasIntroAnimation();
    const videoItems = page.items.getVideoItems();
    const stickerItems = page.items.getStickerItems();
    const transcriptItems = page.items.getAllTranscriptItems();
    const slideshowGraphicItems = page.items.getSlideshowItems();
    const hasVideoItems = videoItems.length !== 0;
    const hasStickerItems = stickerItems.length !== 0;
    const hasSlideshowItems = slideshowGraphicItems.length !== 0;
    const hasTranscriptItems = transcriptItems.length !== 0;

    if (!hasStickerItems && !hasVideoItems && !posterHasAnimation && !hasSlideshowItems && !hasTranscriptItems) {
      throw new Error('No video item or animations on poster');
    }

    page.pageWatermark.setWatermark({
      mode: this.poster.mode.details.showLargeWatermarkOnVideoGeneration ? PAGE_WATERMARK_MODE.VIDEO_LARGE : PAGE_WATERMARK_MODE.NONE,
    });

    this.poster.getCurrentPage().introAnimation.initializeGraphicItemAnimations();
  }

  public getVideoItemFrameTimesForPosterTime(posterTime: number): Record<string, number> {
    const videoFrameTimesHashMap: Record<string, number> = {};
    const videoItems = [...this.poster.getCurrentPage().items.getVideoItems(), ...this.poster.getCurrentPage().items.getVideoSlides()];
    for (const videoItem of videoItems) {
      videoFrameTimesHashMap[videoItem.uid] = secondsToMicroSeconds(videoItem.getFrameTimeForPosterTime(posterTime));
    }

    return videoFrameTimesHashMap;
  }

  public async generateFrameForPosterTime(posterTime: number, videoItemFrames: Record<string, string>): Promise<GenerateFrameForPosterTimeResponse> {
    const promises = [];
    const startTime = performance.now();
    promises.push(this.setVideoItemsFrame(videoItemFrames));
    promises.push(this.setGenerationSeekableItemsFrame(posterTime));
    promises.push(this.setSlideshowItemsFrame(posterTime, videoItemFrames));

    await Promise.all(promises);
    this.setPageAnimationFrame(posterTime);
    const setFrameTime = performance.now();

    this.poster.getCurrentPage().fabricCanvas.renderAll();
    const renderAllTime = performance.now();

    const imageData = this.poster.getCurrentPage().fabricCanvas.toDataURL({
      format: 'jpeg' as Fabric.ImageFormat,
      enableRetinaScaling: false,
      multiplier: 1,
      quality: this.poster.isHighRes ? 0.98 : 0.8,
    });
    const captureCanvasTime = performance.now();

    return {
      imageData,
      logData: {
        setFrameTime: setFrameTime - startTime,
        renderAllTime: renderAllTime - setFrameTime,
        captureCanvasTime: captureCanvasTime - renderAllTime,
        totalTimeToGeneratePosterFromFrontEnd: performance.now() - startTime,
      },
    };
  }

  /**
   * Sets animation frame for time
   * @param {number} frameTime
   */

  private setPageAnimationFrame(frameTime: number): void {
    const posterAnimationDuration = this.poster.getCurrentPage().introAnimation.getAnimationMaxDuration();
    if (this.poster.getCurrentPage().introAnimation.hasIntroAnimation() && frameTime < posterAnimationDuration) {
      this.poster.getCurrentPage().introAnimation.setFrame(frameTime);
    }
  }

  private async setVideoItemsFrame(videoItemFrames: Record<string, string>): Promise<void> {
    const videoItems = this.poster.getCurrentPage().items.getVideoItems();

    if (!videoItems) {
      return;
    }

    const setVideoFramePromises = [];

    for (const videoItem of videoItems) {
      setVideoFramePromises.push(videoItem.setImageFrame(videoItemFrames[videoItem.uid]));
    }

    await Promise.all(setVideoFramePromises);
  }

  private async setGenerationSeekableItemsFrame(frameTime: number): Promise<void> {
    const items = this.poster.getCurrentPage().items.getGenerationSeekableItems();

    if (!items) {
      return;
    }

    const setGenerationSeekableItemsFramePromises = [];

    for (const item of items) {
      setGenerationSeekableItemsFramePromises.push(item.seek(frameTime));
    }

    await Promise.all(setGenerationSeekableItemsFramePromises);
  }

  public async setSlideshowItemsFrame(frameTime: number, videoItemFrames: Record<string, string>): Promise<void> {
    const slideshowItems = this.poster.getCurrentPage().items.getSlideshowItems();

    if (!slideshowItems) {
      return;
    }

    const promises = [];
    for (const slideshowItem of slideshowItems) {
      promises.push(slideshowItem.setFrameForGeneration(frameTime, videoItemFrames));
    }

    await Promise.all(promises);
  }
}
