import {HistoryState} from '@PosterWhiteboard/poster/poster-history/history-state';
import type {PosterLoadObject} from '@PosterWhiteboard/poster/poster.types';
import type {DeepPartial} from '@/global';

export class HistoryStatePoster extends HistoryState {
  public posterLoadObject: DeepPartial<PosterLoadObject>;

  public constructor(posterLoadObject: DeepPartial<PosterLoadObject>) {
    super();
    this.posterLoadObject = posterLoadObject;
  }
}
