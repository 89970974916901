import type {PosterLoadObject, PosterPageObject} from '@PosterWhiteboard/poster/poster.types';
import {BackgroundTypeBackend, GlowType, GradientBackgroundBackendType, ShadowType} from '@PosterWhiteboard/poster/poster-backend.types';
import type {
  BackgroundBackendObject,
  FancyTextItemBackendObject,
  ImageBackgroundItemBackendObject,
  ImageBackgroundItemObjectConvertedFromBackend,
  ImageItemBackendObject,
  ImageSlideItemBackendObject,
  ImageSlideItemObjectConvertedFromBackend,
  ItemBackendObject,
  ItemBackendObjectTypeWithTextStyles,
  ItemObjectConvertedFromBackend,
  MaskingFreehandBackend,
  MaskingItemBackend,
  MaskingShapeBackend,
  MaskingTextBackend,
  MenuItemBackendObject,
  NonAudioItemBackendObject,
  PageBackendObject,
  TranscriptItemBackendObject,
  TranscriptItemObjectConvertedFromBackend,
  QRCodeItemBackendObject,
  SlideshowItemBackendObject,
  StickerItemBackendObject,
  TableItemBackendObject,
  TabsItemBackendObject,
  TextItemBackendObject,
  TextItemObjectConvertedFromBackend,
  TextListBackend,
  TextSlideItemBackendObject,
  TextSlideItemObjectConvertedFromBackend,
  VectorItemBackendObject,
  VideoItemBackendObject,
  VideoSlideItemBackendObject,
  VideoSlideItemObjectConvertedFromBackend,
  RectangleItemBackendObject,
  PosterObjectBackend,
} from '@PosterWhiteboard/poster/poster-backend.types';
import type {PageObject} from '@PosterWhiteboard/page/page.types';
import type {BaseItemObject, ItemObject, SlideItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {PageBackgroundObject} from '@PosterWhiteboard/page/page-background.class';
import type {AudioClipsObject} from '@PosterWhiteboard/classes/audio-clips/audio-clips.class';
import type {ItemEffectsObject} from '@PosterWhiteboard/classes/item-effects.class';
import {EdgeType} from '@PosterWhiteboard/classes/item-effects.class';
import type {TextHorizontalAlignType, TextStylesObject} from '@PosterWhiteboard/classes/text-styles.class';
import type {RGBA} from '@Utils/color.util';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import type {QRCodeItemObject} from '@PosterWhiteboard/items/qr-code-item.class';
import type {ItemCropObject} from '@PosterWhiteboard/classes/item-crop.class';
import type {RemoveImageItemBackgroundObject} from '@PosterWhiteboard/items/image-item/remove-image-item-background.class';
import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {VideoItemObject} from '@PosterWhiteboard/items/video-item/video-item.class';
import type {StickerItemObject} from '@PosterWhiteboard/items/sticker-item.class';
import type {TableItemObject} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {TextListObject} from '@PosterWhiteboard/items/text-item/text-list';
import type {MenuItemObject} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import type {TabsItemObject} from '@PosterWhiteboard/items/tabs-item/tabs-item.class';
import type {ColorBackgroundObject} from '@PosterWhiteboard/page/background/color-background.class';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {ImageBackgroundObject} from '@PosterWhiteboard/page/background/image-background.class';
import type {SlideshowItemObject} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {ItemMaskingObject, MaskingItemObject} from '@PosterWhiteboard/classes/item-masking.class';
import type {MaskingShapeObject} from '@PosterWhiteboard/classes/masking/masking-shape.class';
import {MaskingType} from '@PosterWhiteboard/classes/masking/masking.class';
import type {MaskingTextObject} from '@PosterWhiteboard/classes/masking/masking-text.class';
import type {MaskingFreehandObject} from '@PosterWhiteboard/classes/masking/masking-freehand.class';
import type {TransitionObject} from '@PosterWhiteboard/models/transition.class';
import type {FancyTextItemObject} from '@PosterWhiteboard/items/fancy-text-item/fancy-text-item.class';
import type {ImageBackgroundItemObject} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {AnimationSpeed, AnimationType, PositionType, SlideType} from '@PosterWhiteboard/animation/animation.class';
import type {MediaSlideObject} from '@PosterWhiteboard/items/slideshow-item/media-slide.class';
import type {UserObject} from '@PosterWhiteboard/user/user.types';
import type {ItemAuraObject} from '@PosterWhiteboard/classes/item-aura.class';
import {AuraType} from '@PosterWhiteboard/classes/item-aura.class';
import type {RemoveVideoItemBackgroundObject} from '@PosterWhiteboard/items/video-item/remove-video-item-background.class';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {RectangleItemObject} from '@PosterWhiteboard/items/rectangle-item/rectangle-item';
import type {ItemBorderObject} from '../classes/item-border.class';
import {BorderType} from '../classes/item-border.class';
import type {AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';

interface GetLoadedImageDimensionsResponse {
  loadedImageHeight: number;
  loadedImageWidth: number;
}

export const getPosterObjectFromPosterBackendObject = (posterBackendObject: PosterObjectBackend): PosterLoadObject => {
  return {
    createdOn: posterBackendObject.createdOn,
    description: posterBackendObject.description,
    hashedID: posterBackendObject.hashedID,
    id: posterBackendObject.id,
    idGalleryTemplate: posterBackendObject.idGalleryTemplate ?? undefined,
    idGalleryTemplateCreator: posterBackendObject.idGalleryTemplateCreator,
    idLastModifier: posterBackendObject.idLastModifier,
    idTemplate: posterBackendObject.idTemplate ?? '',
    isCopyable: posterBackendObject.isCopyable,
    isInternal: posterBackendObject.isInternal,
    isPublic: posterBackendObject.isPublic,
    isPurchaseable: posterBackendObject.isPurchaseable,
    isTemplate: posterBackendObject.isTemplate,
    lastModified: posterBackendObject.lastModified,
    name: posterBackendObject.name,
    seoName: posterBackendObject.seoName,
    type: {
      name: posterBackendObject.type.name,
      displayName: posterBackendObject.type.displayName,
      description: posterBackendObject.type.description,
      keywords: posterBackendObject.type.keywords,
      width: posterBackendObject.type.width ?? posterBackendObject.width,
      height: posterBackendObject.type.height ?? posterBackendObject.height,
      widthInch: posterBackendObject.type.widthInch,
      heightInch: posterBackendObject.type.heightInch,
      widthMm: posterBackendObject.type.widthMm,
      heightMm: posterBackendObject.type.heightMm,
      widthCm: posterBackendObject.type.widthCm,
      heightCm: posterBackendObject.type.heightCm,
      widthFt: posterBackendObject.type.widthFt,
      heightFt: posterBackendObject.type.heightFt,
      dpi: posterBackendObject.type.dpi,
      idUserPosterType: posterBackendObject.type.idUserPosterType,
      rotatable: posterBackendObject.type.rotatable,
      hasPrintProduct: posterBackendObject.type.hasPrintProduct,
      scaleFactors: posterBackendObject.type.scaleFactors,
    },
    units: posterBackendObject.units,
    userHeight: posterBackendObject.userHeight,
    userWidth: posterBackendObject.userWidth,
    version: Number(posterBackendObject.version),
    pages: getPosterPagesObjectFromBackendObject(posterBackendObject.pages),
    audioClips: getAudioClipsObjectFromPosterBackendObject(posterBackendObject),
    width: parseInt(posterBackendObject.width.toString(), 10),
    height: parseInt(posterBackendObject.height.toString(), 10),
    creator: getCreatorObjectFromBackendObject(posterBackendObject),
  };
};

const getAudioClipsObjectFromPosterBackendObject = (posterBackendObject: PosterObjectBackend): AudioClipsObject => {
  const audioItemsHashMap: Record<string, AudioItemObject> = {};

  for (const [audioItemId, audioItemBackendObject] of Object.entries(posterBackendObject.audioClips.audioItemsHashMap)) {
    audioItemsHashMap[audioItemId] = {
      uid: audioItemBackendObject.uid,
      hashedFilename: audioItemBackendObject.hashedFilename,
      source: audioItemBackendObject.source,
      onPosterStartTime: audioItemBackendObject.onPosterStartTime,
      name: audioItemBackendObject.name,
      audioPlayer: {
        isPlaying: false,
        audioUrl: audioItemBackendObject.audioPlayer.audioUrl,
        trim: audioItemBackendObject.audioPlayer.trim,
        fade: audioItemBackendObject.audioPlayer.fade,
        originalDuration: audioItemBackendObject.audioPlayer.originalDuration,
        playCycles: audioItemBackendObject.audioPlayer.playCycles,
        volume: audioItemBackendObject.audioPlayer.volume,
        speed: audioItemBackendObject.audioPlayer.speed,
      },
    };
  }

  return {
    audioItemsHashMap,
    selectedAudioItemUID: '',
  };
};

const getCreatorObjectFromBackendObject = (posterBackendObject: PosterObjectBackend): UserObject | undefined => {
  return posterBackendObject.creator
    ? {
        id: posterBackendObject.creator.id,
        fbId: posterBackendObject.creator.fbId,
        name: posterBackendObject.creator.name,
        type: posterBackendObject.creator.type,
        watermark: posterBackendObject.creator.watermark !== '-1' ? posterBackendObject.creator.watermark : undefined,
        premiumLevel: posterBackendObject.creator.premiumLevel,
        preferredLanguage: posterBackendObject.creator.preferredLanguage,
        verificationNeededStatus: posterBackendObject.creator.verificationNeededStatus,
      }
    : undefined;
};

const getPosterPagesObjectFromBackendObject = (pagesBackendObject: PageBackendObject[]): PosterPageObject => {
  const pagesHashMap: Record<string, PageObject> = {};
  const pageOrder: string[] = [];

  for (const pageBackendObject of pagesBackendObject) {
    const pageObject = getPageObjectFromBackendPageObject(pageBackendObject);
    if (pageObject.hashedID) {
      pagesHashMap[pageObject.hashedID] = pageObject;
      pageOrder.push(pageObject.hashedID);
    }
  }

  return {
    pagesHashMap,
    pageOrder,
  };
};

const getPageObjectFromBackendPageObject = (pageBackendObject: PageBackendObject): PageObject => {
  const {graphicItems, background, ...obj} = {...pageBackendObject};
  const itemsHashMap: Record<string, ItemObject> = {};
  const itemsOrder: string[] = [];

  for (const itemBackendObject of graphicItems) {
    itemsHashMap[itemBackendObject.uid] = getItemObjectFromBackendItemObject(itemBackendObject) as ItemObject;
    itemsOrder.push(itemBackendObject.uid);
  }

  return {
    hashedID: obj.hashedID,
    duration: obj.duration,
    background: getPageBackgroundObjectFromBackendBackgroundObject(background),
    items: {
      itemsHashMap,
      itemsOrder,
    },
    introAnimation: {
      animation: {
        // TODO: need to see if we can remove the "as" somehow
        type: pageBackendObject.introAnimation.type as AnimationType,
        slideType: pageBackendObject.introAnimation.slideType as SlideType | PositionType,
        speed: pageBackendObject.introAnimation.speed as AnimationSpeed,
      },
    },
  };
};

const getItemObjectFromBackendItemObject = (itemBackendObject: ItemBackendObject): ItemObjectConvertedFromBackend<ItemObject> => {
  switch (itemBackendObject.gitype) {
    case ITEM_TYPE.IMAGE:
      return getImageItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.IMAGESLIDE:
      return getImageSlideItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.QR_CODE:
      return getQRCodeItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.FANCY_TEXT:
      return getFancyTextItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.VECTOR:
      return getVectorItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.VIDEO:
      return getVideoItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.VIDEOSLIDE:
      return getVideoSlideItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.STICKER:
      return getStickerItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.TEXT:
      return getTextItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.TEXTSLIDE:
      return getTextSlideItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.TABLE:
      return getTableItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.MENU:
      return getMenuItemObjectFromBackendItemObject(itemBackendObject as MenuItemBackendObject);

    case ITEM_TYPE.TAB:
      return getTabsItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.SLIDESHOW:
      return getSlideshowItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.TRANSCRIPT:
      return getTranscriptItemObjectFromBackendItemObject(itemBackendObject);

    case ITEM_TYPE.RECTANGLE:
      return getRectangleItemObjectFromBackendItemObject(itemBackendObject);

    default:
      throw new Error(`unhandled graphic item: ${itemBackendObject.gitype}`);
  }
};

const getTableItemObjectFromBackendItemObject = (itemBackendObject: TableItemBackendObject): ItemObjectConvertedFromBackend<TableItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    textStyles: getTextStylesFromBackendObject(itemBackendObject),
    rows: itemBackendObject.rows,
    columns: itemBackendObject.columns,
    layoutDataMap: itemBackendObject.layoutDataMap,
    unusedData: itemBackendObject.unusedData,
    layoutStyle: itemBackendObject.layoutStyle,
    alternateBackgroundColor1: itemBackendObject.alternateBackgroundColor1,
    alternateBackgroundColor2: itemBackendObject.alternateBackgroundColor2,
    highlightedBackgroundColor: itemBackendObject.highlightedBackgroundColor,
    highlightedTextColor: itemBackendObject.highlightedTextColor,
    xSpacing: itemBackendObject.xSpacing,
    ySpacing: itemBackendObject.ySpacing,
    fontFamily2: itemBackendObject.fontFamily2,
    isBold2: itemBackendObject.isBold2,
    isItalic2: itemBackendObject.isItalic2,
    underLine2: itemBackendObject.underLine2,
    lineThrough2: itemBackendObject.lineThrough2,
    backgroundType: itemBackendObject.backgroundType,
    backgroundColor: itemBackendObject.backgroundColor,
  };
};

const getMenuItemObjectFromBackendItemObject = (itemBackendObject: MenuItemBackendObject): ItemObjectConvertedFromBackend<MenuItemObject> => {
  return {
    ...getTableItemObjectFromBackendItemObject(itemBackendObject),
    itemIds: itemBackendObject.itemIds,
    copiedItemIds: itemBackendObject.copiedItemIds,
    iconsColor: itemBackendObject.iconsColor,
    iconsSize: itemBackendObject.iconsSize,
    wrappingInfo: itemBackendObject.wrappingInfo,
  };
};

const getSubtitleObjectsHashmapFromBackendItemObject = (itemBackendObject: TranscriptItemBackendObject): Record<string, SubtitleObject> => {
  const subtitleObjectsHashmap: Record<string, SubtitleObject> = {};

  Object.entries(itemBackendObject.subtitlesHashmap).forEach(([uid, subtitleBackendObject]) => {
    subtitleObjectsHashmap[uid] = {
      words: subtitleBackendObject.words,
      subtitleUID: subtitleBackendObject.subtitleUID,
      text: subtitleBackendObject.text,
      startTime: subtitleBackendObject.startTime,
      endTime: subtitleBackendObject.endTime,
      hasUserEdited: subtitleBackendObject.hasUserEdited,
      sentenceTextStyles: subtitleBackendObject.sentenceTextStyles,
      activeWordTextStyles: subtitleBackendObject.activeWordTextStyles,
      aura: subtitleBackendObject.aura,
      backgroundFill: subtitleBackendObject.backgroundFill,
      backgroundBorderRadius: subtitleBackendObject.backgroundBorderRadius,
      selectedTemplateId: subtitleBackendObject.selectedTemplateId,
      animationStyle: subtitleBackendObject.animationStyle,
    };
  });

  return subtitleObjectsHashmap;
};

const getTranscriptItemObjectFromBackendItemObject = (itemBackendObject: TranscriptItemBackendObject): TranscriptItemObjectConvertedFromBackend => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    subtitlesHashmap: getSubtitleObjectsHashmapFromBackendItemObject(itemBackendObject),
    verticalAlign: itemBackendObject.verticalAlign,
    generatedFrom: itemBackendObject.generatedFrom,
  };
};

const getTextItemObjectFromBackendItemObject = (itemBackendObject: TextItemBackendObject | TextSlideItemBackendObject): TextItemObjectConvertedFromBackend => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    background: itemBackendObject.background,
    textStyles: getTextStylesFromBackendObject(itemBackendObject),
    baseWidth: itemBackendObject.baseWidth,
    backgroundColor: itemBackendObject.backgroundColor,
    backgroundType: itemBackendObject.backgroundType,
    fontFamily: itemBackendObject.fontFamily,
    verticalAlign: itemBackendObject.verticalAlign,
    verticalPadding: itemBackendObject.verticalPadding,
    list: getTextListFromBackendTextListObject(itemBackendObject.list),
    text: itemBackendObject.text,
    wrappedLines: itemBackendObject.wrappedLines,
  };
};

const getTextSlideItemObjectFromBackendItemObject = (itemBackendObject: TextSlideItemBackendObject): TextSlideItemObjectConvertedFromBackend => {
  return {
    ...getTextItemObjectFromBackendItemObject(itemBackendObject),
    slideDuration: itemBackendObject.slideDuration,
  };
};

const getImageSlideItemObjectFromBackendItemObject = (itemBackendObject: ImageSlideItemBackendObject): ImageSlideItemObjectConvertedFromBackend => {
  return {
    ...getImageItemObjectFromBackendItemObject(itemBackendObject),
    slideDuration: itemBackendObject.slideDuration,
    mediaSlide: getMediaSlideFromBackendObject(itemBackendObject),
  };
};

const getVideoSlideItemObjectFromBackendItemObject = (itemBackendObject: VideoSlideItemBackendObject): VideoSlideItemObjectConvertedFromBackend => {
  return {
    ...getVideoItemObjectFromBackendItemObject(itemBackendObject),
    slideDuration: itemBackendObject.slideDuration,
    mediaSlide: getMediaSlideFromBackendObject(itemBackendObject),
  };
};

const getImageBackgroundItemObjectFromBackendItemObject = (itemBackendObject: ImageBackgroundItemBackendObject): ImageBackgroundItemObjectConvertedFromBackend => {
  return {
    ...getImageItemObjectFromBackendItemObject(itemBackendObject),
    transparency: itemBackendObject.transparency,
    attributionName: itemBackendObject.attributionName,
    attributionURL: itemBackendObject.attributionURL,
    licenseName: itemBackendObject.licenseName,
    licenseURL: itemBackendObject.licenseURL,
    displayAttribution: itemBackendObject.displayAttribution,
    idUser: itemBackendObject.idUser,
  };
};

const getStickerItemObjectFromBackendItemObject = (itemBackendObject: StickerItemBackendObject): ItemObjectConvertedFromBackend<StickerItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    effects: getEffectObjectFromBackendObject(itemBackendObject),
    hashedFilename: itemBackendObject.hashedFilename,
    duration: itemBackendObject.duration,
    frameRate: itemBackendObject.frameRate,
    highResAnimatedSprite: itemBackendObject.highResAnimatedSprite,
    screenAnimatedSprite: itemBackendObject.screenAnimatedSprite,
  };
};

const getVideoItemObjectFromBackendItemObject = (itemBackendObject: VideoItemBackendObject | VideoSlideItemBackendObject): ItemObjectConvertedFromBackend<VideoItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    effects: getEffectObjectFromBackendObject(itemBackendObject),
    hashedFilename: itemBackendObject.hashedFilename,
    duration: itemBackendObject.duration,
    hasTransparency: itemBackendObject.hasTransparency,
    frameRate: itemBackendObject.frameRate,
    startTime: itemBackendObject.startTime,
    endTime: itemBackendObject.endTime,
    isMuted: itemBackendObject.isMuted,
    videoSource: itemBackendObject.videoSource,
    fileExtension: itemBackendObject.fileExtension,
    removeBackground: getRemoveVideoBackgroundObjectFromBackendObject(itemBackendObject),
  };
};

const getRectangleItemObjectFromBackendItemObject = (itemBackendObject: RectangleItemBackendObject): ItemObjectConvertedFromBackend<RectangleItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    fill: itemBackendObject.fill,
    rx: itemBackendObject.rx,
    ry: itemBackendObject.ry,
  };
};

const getVectorItemObjectFromBackendItemObject = (itemBackendObject: VectorItemBackendObject): ItemObjectConvertedFromBackend<VectorItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    fill: {
      fillType: itemBackendObject.fillType,
      fillColor: getFillColorForVectorItemBackendObject(itemBackendObject),
    },
    fileName: itemBackendObject.fileName,
    source: itemBackendObject.source,
    isCustomisable: !itemBackendObject.isComplexSVG,
  };
};

const getQRCodeItemObjectFromBackendItemObject = (itemBackendObject: QRCodeItemBackendObject): ItemObjectConvertedFromBackend<QRCodeItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    qrForegroundColor: itemBackendObject.qrForegroundColor,
    qrBackgroundColor: itemBackendObject.qrBackgroundColor,
    message: itemBackendObject.message,
    isBackgroundTransparent: itemBackendObject.isBackgroundTransparent,
  };
};

const getFancyTextItemObjectFromBackendItemObject = (itemBackendObject: FancyTextItemBackendObject): ItemObjectConvertedFromBackend<FancyTextItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    idFancyText: itemBackendObject.idFancyText,
    text: itemBackendObject.text,
    fontFamily: itemBackendObject.fontFamily,
    colors: getFancyTextColorArray(itemBackendObject),
    morphType: itemBackendObject.morphType,
    morphAmount: itemBackendObject.morphAmount,
  };
};

const getImageItemObjectFromBackendItemObject = (
  itemBackendObject: ImageItemBackendObject | ImageSlideItemBackendObject | ImageBackgroundItemBackendObject
): ItemObjectConvertedFromBackend<ImageItemObject> => {
  const {loadedImageWidth, loadedImageHeight} = getLoadedImageDimensions(itemBackendObject);

  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    loadedImageWidth,
    loadedImageHeight,
    hashedFilename: itemBackendObject.hashedFilename,
    fileExtension: itemBackendObject.fileExtension,
    isRemoved: itemBackendObject.isRemoved,
    isPurchased: itemBackendObject.isPurchased,
    imageSource: itemBackendObject.imageSource,
    hasTransparency: itemBackendObject.hasTransparency,
    showReplaceButton: false,
    masking: getMaskingObjectFromBackendObject(itemBackendObject),
    effects: getEffectObjectFromBackendObject(itemBackendObject),
    cropData: getCropDataFromBackendObject(itemBackendObject),
    removeBackground: getRemoveBackgroundObjectFromBackendObject(itemBackendObject),
  };
};

const getLoadedImageDimensions = (itemBackendObject: ImageItemBackendObject | ImageSlideItemBackendObject | ImageBackgroundItemBackendObject): GetLoadedImageDimensionsResponse => {
  if (itemBackendObject.loadedImageHeight && itemBackendObject.loadedImageWidth) {
    return {
      loadedImageWidth: itemBackendObject.loadedImageWidth,
      loadedImageHeight: itemBackendObject.loadedImageHeight,
    };
  }

  /**
   * Old images had no data for loaded image dimensions. The dimensions changed depending upon being cropped or having outline border.
   * Here we reverse calculate the loaded image dimensions on which these operations happened
   */
  const hasOutlineBorder = itemBackendObject.solidBorderType === 3;
  if (itemBackendObject.cropData === null && !hasOutlineBorder) {
    return {
      loadedImageHeight: itemBackendObject.height,
      loadedImageWidth: itemBackendObject.width,
    };
  }

  if (itemBackendObject.cropData !== null && !hasOutlineBorder) {
    return {
      loadedImageHeight: itemBackendObject.height * (itemBackendObject.cropData.imageHeight / itemBackendObject.cropData.height),
      loadedImageWidth: itemBackendObject.width * (itemBackendObject.cropData.imageWidth / itemBackendObject.cropData.width),
    };
  }

  /**
   * Here its confirmed that we have an outline.
   *  For details of how I came up with these equations vist: https://docs.google.com/document/d/1fYH_XhHLWfOA-ie8zbv0ZT4tR4UVoZLitLKJVwNSgd4/edit#heading=h.mtlz8pjl6txx
   */
  let loadedImageHeight = itemBackendObject.height;
  let loadedImageWidth = itemBackendObject.width;
  const EFFECTS_REFERENCE_DIMENSION = 500;

  let loadedImageHeightWithScaledOutline: number;
  let loadedImageWidthWithScaledOutline: number;
  if (itemBackendObject.cropData !== null) {
    loadedImageHeightWithScaledOutline = itemBackendObject.height * (itemBackendObject.cropData.imageHeight / itemBackendObject.cropData.height);
    loadedImageWidthWithScaledOutline = itemBackendObject.width * (itemBackendObject.cropData.imageWidth / itemBackendObject.cropData.width);
  } else {
    loadedImageHeightWithScaledOutline = loadedImageHeight;
    loadedImageWidthWithScaledOutline = loadedImageWidth;
  }

  const originalImageRatio = loadedImageWidthWithScaledOutline / loadedImageHeightWithScaledOutline;

  if (loadedImageWidthWithScaledOutline < loadedImageHeightWithScaledOutline) {
    const cropScale = itemBackendObject.cropData ? itemBackendObject.cropData.imageWidth / itemBackendObject.cropData.width : 1;
    loadedImageWidth = (EFFECTS_REFERENCE_DIMENSION * itemBackendObject.width * cropScale) / (EFFECTS_REFERENCE_DIMENSION + itemBackendObject.solidBorderThickness * cropScale);
    loadedImageHeight = loadedImageWidth / originalImageRatio;
  } else {
    const cropScale = itemBackendObject.cropData ? itemBackendObject.cropData.imageHeight / itemBackendObject.cropData.height : 1;
    loadedImageHeight = (EFFECTS_REFERENCE_DIMENSION * itemBackendObject.height * cropScale) / (EFFECTS_REFERENCE_DIMENSION + itemBackendObject.solidBorderThickness * cropScale);
    loadedImageWidth = originalImageRatio / loadedImageHeight;
  }

  return {
    loadedImageWidth: Math.round(loadedImageWidth),
    loadedImageHeight: Math.round(loadedImageHeight),
  };
};

const getTabsItemObjectFromBackendItemObject = (itemBackendObject: TabsItemBackendObject): ItemObjectConvertedFromBackend<TabsItemObject> => {
  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    textStyles: getTextStylesFromBackendObject(itemBackendObject),
    text: itemBackendObject.text,
    numTabs: itemBackendObject.numTabs,
    separatorColor: itemBackendObject.separatorColor,
    separatorType: itemBackendObject.separatorType,
    backgroundType: itemBackendObject.backgroundType,
    backgroundColor: itemBackendObject.backgroundColor,
  };
};

const getSlideshowItemObjectFromBackendItemObject = (itemBackendObject: SlideshowItemBackendObject): ItemObjectConvertedFromBackend<SlideshowItemObject> => {
  const {slideshowItems} = itemBackendObject;
  const slidesHashMap: Record<string, ItemObjectConvertedFromBackend<SlideItemObject>> = {};
  const slidesOrder: string[] = [];

  for (const slideBackendObject of slideshowItems) {
    // TODO: Remove this try catch once all items have been implemented
    try {
      slidesHashMap[slideBackendObject.uid] = getItemObjectFromBackendItemObject(slideBackendObject) as ItemObjectConvertedFromBackend<SlideItemObject>;
      slidesOrder.push(slideBackendObject.uid);
    } catch (e) {
      console.error(e);
    }
  }

  return {
    ...getBaseItemObjectFromBackendItemObject(itemBackendObject),
    introAnimationPadding: itemBackendObject.introAnimationPadding,
    introDelay: itemBackendObject.introDelay,
    hasIntroOutroTransition: itemBackendObject.hasIntroOutroTransition,
    transition: getTransitionObjectFromBackendObject(itemBackendObject),
    slides: {
      // @ts-expect-error TODO: Will fix.
      slidesHashMap,
      slidesOrder,
    },
  };
};

const getTransitionObjectFromBackendObject = (itemBackendObject: SlideshowItemBackendObject): TransitionObject => {
  return {
    type: itemBackendObject.transition.type,
    speed: itemBackendObject.transition.speed,
    color: itemBackendObject.transition.color,
  };
};

const getBaseItemObjectFromBackendItemObject = (itemBackendObject: NonAudioItemBackendObject): ItemObjectConvertedFromBackend<BaseItemObject> => {
  return {
    uid: itemBackendObject.uid,
    gitype: itemBackendObject.gitype,
    idOriginalOwner: itemBackendObject.idOriginalOwner ?? undefined,
    x: itemBackendObject.x,
    y: itemBackendObject.y,
    alpha: itemBackendObject.alpha,
    width: itemBackendObject.width,
    height: itemBackendObject.height,
    rotation: itemBackendObject.rotation,
    visible: itemBackendObject.visible,
    clickableLink: itemBackendObject.clickableLink,
    scaleX: itemBackendObject.scaleX,
    scaleY: itemBackendObject.scaleY,
    flipX: itemBackendObject.flipX,
    flipY: itemBackendObject.flipY,
    lockMovement: itemBackendObject.lockMovement,
    version: itemBackendObject.version,
    aura: getAuraObjectFromBackendObject(itemBackendObject),
    border: getBorderObjectFromBackendObject(itemBackendObject),
  };
};

const getBorderObjectFromBackendObject = (itemBackendObject: ItemBackendObject): ItemBorderObject => {
  const itemIsVector = itemBackendObject.gitype === ITEM_TYPE.VECTOR;

  return {
    solidBorderType: itemIsVector ? BorderType.RECTANGLE_BORDER : itemBackendObject.solidBorderType,
    solidBorderColor: itemIsVector ? itemBackendObject.strokeColor : itemBackendObject.solidBorderColor,
    solidBorderThickness: itemIsVector ? itemBackendObject.strokeWeight : itemBackendObject.solidBorderThickness,
  };
};

const getRemoveBackgroundObjectFromBackendObject = (
  itemBackendObject: ImageItemBackendObject | ImageSlideItemBackendObject | ImageBackgroundItemBackendObject
): RemoveImageItemBackgroundObject => {
  return {
    isBackgroundRemoved: itemBackendObject.isBackgroundRemoved,
    isBackgroundBeingRemoved: false,
  };
};

const getCropDataFromBackendObject = (itemBackendObject: ImageItemBackendObject | ImageSlideItemBackendObject | ImageBackgroundItemBackendObject): ItemCropObject => {
  return {
    cropped: itemBackendObject.cropData !== null,
    x: itemBackendObject.cropData !== null ? itemBackendObject.cropData.x : 0,
    y: itemBackendObject.cropData !== null ? itemBackendObject.cropData.y : 0,
    width: itemBackendObject.cropData !== null ? itemBackendObject.cropData.width : 0,
    height: itemBackendObject.cropData !== null ? itemBackendObject.cropData.height : 0,
    imageWidth: itemBackendObject.cropData !== null ? itemBackendObject.cropData.imageWidth : 0,
    imageHeight: itemBackendObject.cropData !== null ? itemBackendObject.cropData.imageHeight : 0,
  };
};

const getTextListFromBackendTextListObject = (textListBackendObject: TextListBackend): TextListObject => {
  return {
    type: textListBackendObject.type,
    style: textListBackendObject.style,
    width: textListBackendObject.width,
    fill: {
      fillColor: reformatTextListFillColor(textListBackendObject),
      fillType: textListBackendObject.colorType,
    },
  };
};
const getTextStylesFromBackendObject = (itemBackendObject: ItemBackendObjectTypeWithTextStyles): TextStylesObject => {
  return {
    fontSize: itemBackendObject.fontSize,
    fontFamily: itemBackendObject.fontFamily,
    fontStyle: itemBackendObject.fontStyle,
    fontWeight: itemBackendObject.fontWeight,
    letterSpacing: itemBackendObject.letterSpacing,
    fontLicense: itemBackendObject.fontLicense,
    leading: itemBackendObject.leading,
    textAlign: itemBackendObject.textAlign as TextHorizontalAlignType,
    script: itemBackendObject.script,
    isBold: itemBackendObject.isBold,
    isItalic: itemBackendObject.isItalic,
    underLine: itemBackendObject.underLine,
    lineThrough: itemBackendObject.lineThrough,
    stroke: itemBackendObject.stroke,
    strokeColor: itemBackendObject.strokeColor,
    strokeWidth: itemBackendObject.strokeWidth,
    fill: {
      fillColor: reformatTextStylesFillColor(itemBackendObject),
      fillType: itemBackendObject.fillType,
    },
  };
};

const getFancyTextColorArray = (itemBackendObject: FancyTextItemBackendObject): RGBA[] => {
  const colorArray: RGBA[] = [];
  if (itemBackendObject.color1 !== -1) {
    colorArray.push(itemBackendObject.color1);
  }
  if (itemBackendObject.color2 !== -1) {
    colorArray.push(itemBackendObject.color2);
  }
  if (itemBackendObject.color3 !== -1) {
    colorArray.push(itemBackendObject.color3);
  }

  return colorArray;
};

const getMaskingObjectFromBackendObject = (itemBackendObject: ImageItemBackendObject | ImageSlideItemBackendObject | ImageBackgroundItemBackendObject): ItemMaskingObject => {
  return {
    maskingItem: itemBackendObject.maskingItems && itemBackendObject.maskingItems.length > 0 ? getMaskingItemFromBackendObject(itemBackendObject.maskingItems[0]) : undefined,
  };
};

const getMaskingItemFromBackendObject = (maskingItem: MaskingItemBackend): MaskingItemObject => {
  if (maskingItem.type === MaskingType.SHAPE) {
    return getShapeMaskingItemFromBackendObject(maskingItem);
  }

  if (maskingItem.type === MaskingType.TEXT) {
    return getTextMaskingItemFromBackendObject(maskingItem);
  }

  if (maskingItem.type === MaskingType.FREEHAND) {
    return getFreehandMaskingItemFromBackendObject(maskingItem);
  }

  throw new Error(`Unknown masking type`);
};

const getTextMaskingItemFromBackendObject = (maskingItem: MaskingTextBackend): MaskingTextObject => {
  return {
    insideMasking: true,
    type: maskingItem.type,
    maskEffect: maskingItem.maskEffect,
    imageWidth: maskingItem.imageWidth,
    imageHeight: maskingItem.imageHeight,
    angle: maskingItem.angle,
    height: maskingItem.height,
    width: maskingItem.width,
    left: maskingItem.left,
    top: maskingItem.top,
    scaleX: maskingItem.scaleX,
    scaleY: maskingItem.scaleY,
    text: maskingItem.text,
    textStyles: {
      fontSize: maskingItem.fontSize,
      fontFamily: maskingItem.fontFamily,
      letterSpacing: maskingItem.charSpacing,
      leading: maskingItem.lineHeight,
      textAlign: maskingItem.textAlign as TextHorizontalAlignType,
      isBold: maskingItem.isBold,
      isItalic: maskingItem.isItalic,
      underLine: maskingItem.underLine,
      lineThrough: maskingItem.lineThrough,
    },
  };
};

const getShapeMaskingItemFromBackendObject = (maskingItem: MaskingShapeBackend): MaskingShapeObject => {
  return {
    ...maskingItem,
  };
};

const getFreehandMaskingItemFromBackendObject = (maskingItem: MaskingFreehandBackend): MaskingFreehandObject => {
  return {
    ...maskingItem,
    insideMasking: false,
  };
};

const getMediaSlideFromBackendObject = (itemBackendObject: ImageSlideItemBackendObject | VideoSlideItemBackendObject): MediaSlideObject => {
  return {
    horizontalAlign: itemBackendObject.horizontalAlign,
    verticalAlign: itemBackendObject.verticalAlign,
  };
};

const getEffectObjectFromBackendObject = (
  itemBackendObject:
    | ImageItemBackendObject
    | ImageSlideItemBackendObject
    | ImageBackgroundItemBackendObject
    | VideoItemBackendObject
    | VideoSlideItemBackendObject
    | StickerItemBackendObject
): ItemEffectsObject => {
  return {
    brightness: itemBackendObject.brightness,
    blackAndWhite: itemBackendObject.blackAndWhite,
    blur: itemBackendObject.blur,
    contrast: itemBackendObject.contrast,
    edgeType: itemBackendObject.edgeType < EdgeType.NONE || itemBackendObject.edgeType === -1 ? EdgeType.NONE : itemBackendObject.edgeType,
    edgeThickness: itemBackendObject.edgeThickness,
    gamma: {
      isEnabled: itemBackendObject.gamma.isEnabled,
      red: itemBackendObject.gamma.red,
      green: itemBackendObject.gamma.green,
      blue: itemBackendObject.gamma.blue,
    },
    invert: itemBackendObject.invert,
    multiply: itemBackendObject.multiply,
    multiplyColor: itemBackendObject.multiplyColor,
    removeColor: itemBackendObject.removeColor,
    removeColorValue: itemBackendObject.removeColorValue,
    removeColorThickness: itemBackendObject.removeColorThickness,
    pixelate: itemBackendObject.pixelate,
    saturation: itemBackendObject.saturation,
    sepia: itemBackendObject.sepia,
    tint: itemBackendObject.tint,
    tintColor: itemBackendObject.tintColor,
    vibrance: itemBackendObject.vibrance,
  };
};

const getAuraObjectFromBackendObject = (itemBackendObject: ItemBackendObject): ItemAuraObject => {
  return {
    type: getAuraTypeFromBackendObject(itemBackendObject),
    dropShadowColor: itemBackendObject.dropShadowColor,
    dropShadowAngle: itemBackendObject.dropShadowAngle,
    dropShadowDistance: itemBackendObject.dropShadowDistance,
    dropShadowBlur: itemBackendObject.dropShadowBlur,
    glowColor: itemBackendObject.glowColor,
  };
};

const getAuraTypeFromBackendObject = (itemBackendObject: ItemBackendObject): AuraType => {
  switch (itemBackendObject.dropShadow) {
    case ShadowType.LIGHT:
      return AuraType.LIGHT_SHADOW;
    case ShadowType.STRONG:
      return AuraType.STRONG_SHADOW;
    case ShadowType.CUSTOM:
      return AuraType.CUSTOM_SHADOW;
    default:
      break;
  }

  if ('glowType' in itemBackendObject) {
    switch (itemBackendObject.glowType) {
      case GlowType.LIGHT:
        return AuraType.LIGHT_GLOW;
      case GlowType.STRONG:
        return AuraType.STRONG_GLOW;
      default:
        break;
    }
  }

  return AuraType.NONE;
};

const getFillColorForVectorItemBackendObject = (itemBackendObject: VectorItemBackendObject): RGBA[] => {
  const fillColor: RGBA[] = [];
  if (itemBackendObject.fillType === FillTypes.LINEAR_GRADIENT || itemBackendObject.fillType === FillTypes.RADIAL_GRADIENT) {
    fillColor.push(itemBackendObject.gradientFillColor1);
    fillColor.push(itemBackendObject.gradientFillColor2);
  } else {
    fillColor.push(itemBackendObject.fillColor);
  }
  return fillColor;
};

const reformatTextListFillColor = (itemBackendObject: TextListBackend): RGBA[] => {
  const fillColor: RGBA[] = [];
  if (itemBackendObject.colorType === FillTypes.LINEAR_GRADIENT || itemBackendObject.colorType === FillTypes.RADIAL_GRADIENT) {
    fillColor.push(itemBackendObject.gradientColor1);
    fillColor.push(itemBackendObject.gradientColor2);
  } else {
    fillColor.push(itemBackendObject.color);
  }

  return fillColor;
};

const reformatTextStylesFillColor = (itemBackendObject: ItemBackendObjectTypeWithTextStyles): RGBA[] => {
  const color: RGBA[] = [];
  if (itemBackendObject.fillType === FillTypes.SOLID) {
    color.push(itemBackendObject.color);
  } else if (itemBackendObject.fillType === FillTypes.LINEAR_GRADIENT || itemBackendObject.fillType === FillTypes.RADIAL_GRADIENT) {
    color.push(itemBackendObject.gradientFillColor1);
    color.push(itemBackendObject.gradientFillColor2);
  } else {
    // TODO: this is the case when FillType is None and itemBackendObject.color is null
    // the default value should be handled when defining the interface for color
    color.push([184, 184, 184, 1]);
  }
  return color;
};

const getPageBackgroundObjectFromBackendBackgroundObject = (backgroundBackendObject: BackgroundBackendObject): PageBackgroundObject => {
  let details;
  switch (backgroundBackendObject.pmvcName) {
    case BackgroundTypeBackend.SOLID:
    case BackgroundTypeBackend.GRADIENT:
    case BackgroundTypeBackend.TRANSPARENT:
      details = getColorPageBackgroundObjectFromBackendObject(backgroundBackendObject);
      break;

    case BackgroundTypeBackend.IMAGE:
      details = getImagePageBackgroundObjectFromBackendObject(backgroundBackendObject);
      break;

    default:
      throw new Error(`Unhandled backgroundBackendObject.pmvcName`);
  }

  return {
    details,
  };
};

const getImagePageBackgroundObjectFromBackendObject = (backgroundBackendObject: BackgroundBackendObject): ImageBackgroundObject => {
  return {
    type: BackgroundTypeName.IMAGE,
    imageBackgroundItemObject: getImageBackgroundItemObjectFromBackendItemObject(backgroundBackendObject as ImageBackgroundItemBackendObject) as ImageBackgroundItemObject,
  };
};

const getColorPageBackgroundObjectFromBackendObject = (backgroundBackendObject: BackgroundBackendObject): ColorBackgroundObject => {
  return {
    type: BackgroundTypeName.COLOR,
    fill: {
      fillType: getFillTypeForBackgroundBackendObject(backgroundBackendObject),
      fillColor: getFillColorsForBackgroundBackendObject(backgroundBackendObject),
    },
  };
};

const getFillColorsForBackgroundBackendObject = (backgroundBackendObject: BackgroundBackendObject): RGBA[] => {
  const fillColor: RGBA[] = [];

  switch (backgroundBackendObject.pmvcName) {
    case BackgroundTypeBackend.SOLID:
      fillColor.push(backgroundBackendObject.primaryColor);
      break;

    case BackgroundTypeBackend.GRADIENT:
      fillColor.push(backgroundBackendObject.primaryColor, backgroundBackendObject.secondaryColor);
      break;

    case BackgroundTypeBackend.TRANSPARENT:
      fillColor.push([255, 255, 255, 1]);
      break;

    default:
      throw new Error(`backgroundBackendObject type doesn't have colors`);
  }
  return fillColor;
};

const getFillTypeForBackgroundBackendObject = (backgroundBackendObject: BackgroundBackendObject): FillTypes => {
  let fillType;
  switch (backgroundBackendObject.pmvcName) {
    case BackgroundTypeBackend.SOLID:
      fillType = FillTypes.SOLID;
      break;

    case BackgroundTypeBackend.GRADIENT:
      fillType = getFillTypeForGradientBackgroundBackendType(backgroundBackendObject.gradientType);
      break;

    case BackgroundTypeBackend.TRANSPARENT:
      fillType = FillTypes.NONE;
      break;

    default:
      throw new Error(`Unhandled gradient BackgroundTypeBackend ${backgroundBackendObject.pmvcName}`);
  }

  return fillType;
};

const getFillTypeForGradientBackgroundBackendType = (gradientType: GradientBackgroundBackendType): FillTypes => {
  switch (gradientType) {
    case GradientBackgroundBackendType.LINEAR:
      return FillTypes.LINEAR_GRADIENT;
    case GradientBackgroundBackendType.RADIAL:
      return FillTypes.RADIAL_GRADIENT;
    default:
      throw new Error(`Unhandled gradient background type ${gradientType}`);
  }
};

const getRemoveVideoBackgroundObjectFromBackendObject = (itemBackendObject: VideoItemBackendObject | VideoSlideItemBackendObject): RemoveVideoItemBackgroundObject => {
  return {
    isBackgroundRemoved: itemBackendObject.removeBackground.isBackgroundRemoved,
    isBackgroundBeingRemoved: false,
    trimData: {
      startTime: itemBackendObject.removeBackground.startTime,
      endTime: itemBackendObject.removeBackground.endTime,
    },
  };
};
