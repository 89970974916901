import type {Page} from '@PosterWhiteboard/page/page.class';
import type {AnimationConfig} from '@PosterWhiteboard/animation/animation.class';
import {Animation, AnimationType, PositionType, SlideType} from '@PosterWhiteboard/animation/animation.class';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {AnimateItemConfig} from '@PosterWhiteboard/animation/animate-item.class';
import {IntroAnimateItem} from '@PosterWhiteboard/animation/intro-animate-item.class';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {FIXED_SEQUENTIAL_ANIMATION_DELAY_FACTOR, MAX_ITEM_ANIMATION_DELAY_FACTOR, MAX_ITEMS_WITH_FIXED_DELAY_FACTOR} from '@PosterWhiteboard/page/page.types';
import type {DeepPartial} from '@/global';

const DELAY_FACTOR_ZERO = 0;
const DELAY_FACTOR_VECTOR = 0.1;
const DELAY_FACTOR_IMAGE = 0.2;

export interface PageAnimationObject {
  animation: AnimationConfig;
}

export class PageAnimation {
  public page: Page;
  public animation!: Animation;

  public isAnimationPlaying: boolean;
  private graphicItemAnimationsHashMap: Record<string, IntroAnimateItem>;
  private animatingItemsLength: number;

  constructor(page: Page, animation?: Animation) {
    this.page = page;
    this.animation = animation ?? new Animation();

    this.isAnimationPlaying = false;
    this.graphicItemAnimationsHashMap = {};
    this.animatingItemsLength = 0;

    this.page.poster.on('time:updated', this.syncAnimationToPage);
  }

  public toObject(): PageAnimationObject {
    return {
      animation: this.animation.toObject(),
    };
  }

  public updateFromObject(
    PageAnimationObject: DeepPartial<PageAnimationObject>,
    {undoable = true, checkForDurationUpdate = false, replayPosterOnUpdateDone = false, checkForSlideshowIntroAnimationPadding = false}: UpdateFromObjectOpts = {}
  ): void {
    const {animation} = PageAnimationObject;
    let checkForTransparentBackground = false;
    if (animation) {
      if (this.animation !== undefined) {
        if (!this.hasIntroAnimation() && animation.type !== AnimationType.NONE) {
          checkForTransparentBackground = true;
        }
        this.animation.updateFromObject(animation);
      } else {
        this.animation = this.createAnimationFromObject(animation);
        checkForTransparentBackground = true;
      }

      if (checkForSlideshowIntroAnimationPadding) {
        this.page.items.updateSlideshowIntroAnimationPadding();
      }

      if (checkForDurationUpdate) {
        this.page.calculateAndUpdatePageDuration();
      }

      if (checkForTransparentBackground && this.page.background.isTransparentBackground()) {
        this.page.background.updateBackgroundToBeNonTransparent();
      }

      if (undoable) {
        this.page.poster.history.addPosterHistory();
      }
      this.page.poster.redux.updateReduxData();

      if (replayPosterOnUpdateDone) {
        void this.page.poster.replayPoster();
      }
    }
  }

  public hasIntroAnimation() {
    if (this.animation === null || this.animation === undefined) {
      return false;
    }
    return this.animation.hasAnimation();
  }

  private createAnimationFromObject(animationObject: DeepPartial<AnimationConfig>) {
    return new Animation(animationObject);
  }

  public syncAnimationToPage(this: Poster, time: number) {
    const currentPage = this.getCurrentPage();
    const pageAnimationInstance = currentPage.introAnimation;

    if (!currentPage.poster.clock.isPaused() && pageAnimationInstance.hasIntroAnimation() && pageAnimationInstance.timeForIntroAnimation(time)) {
      pageAnimationInstance.playAnimation();
    }
  }

  public timeForIntroAnimation(time: number) {
    return time < 0.1;
  }

  public playAnimation() {
    if (!this.isAnimationPlaying) {
      this.isAnimationPlaying = true;
      this.animatePage();
    }
  }

  private animatePage(): void {
    this.initializeGraphicItemAnimations();

    const itemsIds = Object.keys(this.graphicItemAnimationsHashMap);
    for (const itemId of itemsIds) {
      this.graphicItemAnimationsHashMap[itemId].start();
    }
  }

  public stopAnimation(): void {
    if (this.isAnimationPlaying) {
      const itemsIds = Object.keys(this.graphicItemAnimationsHashMap);
      for (const itemId of itemsIds) {
        this.graphicItemAnimationsHashMap[itemId].stop();
      }
    }
  }

  public onAnimationEnded(): void {
    this.isAnimationPlaying = false;
    if (this.page && this.page.getDuration() < this.animation.getDuration()) {
      this.page.stopPage();
    }
  }

  public getAnimationMaxDuration(): number {
    return this.animation.hasAnimation() ? this.animation.getDuration() + this.animation.getMaxItemAnimationDelay() + 0.1 : 0;
  }

  public initializeGraphicItemAnimations = (): void => {
    this.graphicItemAnimationsHashMap = {};
    this.animatingItemsLength = 0;

    if (this.hasIntroAnimation()) {
      switch (this.animation.type) {
        case AnimationType.SLIDE:
          this.initSlideAnimation();
          break;

        case AnimationType.RISE:
          this.initRiseAnimation();
          break;

        case AnimationType.BLOCK:
          this.initBlockAnimation();
          break;

        case AnimationType.PAN:
          this.initPanAnimation();
          break;

        case AnimationType.POP:
        case AnimationType.JELLO:
          this.initPopOrJelloAnimation();
          break;

        case AnimationType.TUMBLE:
          this.initTumbleAnimation();
          break;

        case AnimationType.ROTATE:
          this.initRotateAnimation();
          break;

        default:
          this.initDefaultPageAnimation();
          break;
      }
    }
  };

  private initSlideAnimation = () => {
    const isVideoBackground = this.page.items.isVideoItemUsedAsBackground();
    const direction = this.animation.slideType;
    const duration = this.animation.getDuration();
    let delay;

    if (this.page) {
      const itemsIds = Object.keys(this.page.items.itemsHashMap);
      for (const itemId of itemsIds) {
        delay = isVideoBackground ? this.getDelayForModelType(this.page.items.itemsHashMap[itemId].gitype) : 0;
        this.animateItem(this.page.items.itemsHashMap[itemId], {onComplete: this.onAnimationComplete, duration, delay, direction});
      }
    }
  };

  private initRiseAnimation = () => {
    const duration = this.animation.getDuration();
    let delay;

    const items = this.sortGraphicItemsAccordingToPosition(this.page.items.getItems());

    const delayFactor = this.getDelayFactorByTotalObjects();

    if (this.page && items) {
      for (let i = 0; i < items.length; i++) {
        delay = i * delayFactor;
        this.animateItem(items[i], {onComplete: this.onAnimationComplete, duration, delay});
      }
    }
  };

  private initPanAnimation = () => {
    const duration = this.animation.getDuration();
    const direction = this.animation.slideType;
    let delay;

    const items = this.sortGraphicItemsAccordingToPosition(this.page.items.getItems());
    const delayFactor = this.getAlternativeDelayFactor(items?.length);

    if (this.page && items) {
      for (let i = 0; i < items.length; i++) {
        delay = i * delayFactor;
        this.animateItem(items[i], {onComplete: this.onAnimationComplete, duration, delay, direction});
      }
    }
  };

  private initPopOrJelloAnimation = () => {
    const duration = this.animation.getDuration();
    let delay;

    const items = this.sortGraphicItemsAccordingToZIndex();
    const delayFactor = this.getAlternativeDelayFactor(items.length);

    if (this.page && items) {
      for (let i = 0; i < items.length; i++) {
        delay = i * delayFactor;
        this.animateItem(items[i], {onComplete: this.onAnimationComplete, duration, delay});
      }
    }
  };

  private initTumbleAnimation = () => {
    const duration = this.animation.getDuration();
    let delay;
    let direction;

    const items = this.sortGraphicItemsAccordingToPosition(this.page.items.getItems());
    const delayFactor = this.getAlternativeDelayFactor(items?.length);

    if (this.page && items) {
      for (let i = 0; i < items.length; i++) {
        delay = i * delayFactor;
        direction = this.getTumbleAnimationDirection(items[i]);
        this.animateItem(items[i], {onComplete: this.onAnimationComplete, duration, delay, direction});
      }
    }
  };

  private initRotateAnimation = () => {
    const duration = this.animation.getDuration();
    let delay;
    let direction;

    const items = this.sortGraphicItemsAccordingToZIndex();
    const delayFactor = this.getAlternativeDelayFactor(items.length);

    if (this.page && items) {
      for (let i = 0; i < items.length; i++) {
        direction = i % 2 === 0 ? SlideType.LEFT : SlideType.RIGHT;
        delay = i * delayFactor;
        this.animateItem(items[i], {onComplete: this.onAnimationComplete, duration, delay, direction});
      }
    }
  };

  private initDefaultPageAnimation = () => {
    const duration = this.animation.getDuration();
    let delay;

    const items = this.sortGraphicItemsAccordingToZIndex();

    if (this.page && items) {
      for (let i = 0; i < items.length; i++) {
        delay = this.getDelayForModelType(items[i].gitype);
        this.animateItem(items[i], {onComplete: this.onAnimationComplete, duration, delay});
      }
    }
  };

  private initBlockAnimation = () => {
    const duration = this.animation.getDuration();
    let delay;

    let textItems: ItemType[] | undefined = this.page.items.getTextItems();

    if (textItems.length === 0) {
      this.onAnimationEnded();
      return;
    }

    textItems = this.sortGraphicItemsAccordingToPosition(textItems);

    const delayFactor = this.getAlternativeDelayFactor(textItems?.length);

    if (this.page && textItems) {
      for (let i = 0; i < textItems.length; i++) {
        delay = i * delayFactor;
        this.animateItem(textItems[i], {onComplete: this.onAnimationComplete, duration, delay});
      }
    }
  };

  private getTumbleAnimationDirection = (graphicItem: ItemType) => {
    const itemZIndex = this.page.fabricCanvas.getObjects().indexOf(graphicItem.fabricObject);
    switch (itemZIndex % 8) {
      case 0:
        return PositionType.TOP_LEFT;

      case 1:
        return PositionType.BOTTOM;

      case 2:
        return PositionType.RIGHT;

      case 3:
        return PositionType.BOTTOM_RIGHT;

      case 4:
        return PositionType.TOP;

      case 5:
        return PositionType.LEFT;

      case 6:
        return PositionType.TOP_RIGHT;

      default: // case 7:
        return PositionType.BOTTOM_LEFT;
    }
  };

  private onAnimationComplete = () => {
    this.animatingItemsLength -= 1;

    if (this.animatingItemsLength === 0 && this.page) {
      this.onAnimationEnded();
    }
  };

  private getDelayForModelType = (itemType: ITEM_TYPE) => {
    let delayFactor;

    if (this.animation) {
      switch (itemType) {
        case ITEM_TYPE.VIDEO:
        case ITEM_TYPE.STICKER:
          delayFactor = DELAY_FACTOR_ZERO;
          break;

        case ITEM_TYPE.VECTOR:
          delayFactor = DELAY_FACTOR_VECTOR;
          break;

        case ITEM_TYPE.IMAGE:
        case ITEM_TYPE.DRAWING:
          delayFactor = DELAY_FACTOR_IMAGE;
          break;

        case ITEM_TYPE.TEXT:
        case ITEM_TYPE.FANCY_TEXT:
        case ITEM_TYPE.TAB:
        case ITEM_TYPE.TABLE:
        case ITEM_TYPE.MENU:
        case ITEM_TYPE.SLIDESHOW:
        case ITEM_TYPE.TRANSCRIPT:
          delayFactor = MAX_ITEM_ANIMATION_DELAY_FACTOR;
          break;

        default:
          delayFactor = DELAY_FACTOR_ZERO;
          break;
      }

      return this.animation.getDuration() * delayFactor;
    }
    return 0;
  };

  private getAlternativeDelayFactor = (totalGraphicItems = 0) => {
    const duration = this.animation.getDuration();

    if (duration) {
      // keeping a fixed delay of duration * fixedAlternativeDelayFactor between each graphic item if there are at most 6 graphic items.
      if (totalGraphicItems <= MAX_ITEMS_WITH_FIXED_DELAY_FACTOR + 1) {
        return duration * FIXED_SEQUENTIAL_ANIMATION_DELAY_FACTOR;
      }

      /* evenly dividing the delay when there are more than 6 items so that total animation duration
                                    doesn't exceed the duration we have with 6 graphic items.
                                */
      return (duration * FIXED_SEQUENTIAL_ANIMATION_DELAY_FACTOR * MAX_ITEMS_WITH_FIXED_DELAY_FACTOR) / (totalGraphicItems - 1);
    }
    return 0;
  };

  private animateItem = (graphicItem: ItemType, opts: AnimateItemConfig): void => {
    if (this.animation) {
      this.animatingItemsLength += 1;
      this.graphicItemAnimationsHashMap[graphicItem.uid] = new IntroAnimateItem(graphicItem, this.animation.type, opts);
    }
  };

  private sortGraphicItemsAccordingToPosition = (items: ItemType[] | undefined) => {
    if (items) {
      // sorting according to the bound top of the page's graphic items.
      // if the bound top is same, then sorting according to bound left.
      items.sort((a: ItemType, b: ItemType) => {
        const boundTopA = a.fabricObject.getBoundingRect().top;
        const boundLeftA = a.fabricObject.getBoundingRect().left;
        const boundTopB = b.fabricObject.getBoundingRect().top;
        const boundLeftB = b.fabricObject.getBoundingRect().left;

        return boundTopA !== boundTopB ? boundTopA - boundTopB : boundLeftA - boundLeftB;
      });
    }

    return items;
  };

  private sortGraphicItemsAccordingToZIndex = () => {
    const sortedPosterGraphicItems = this.page.items.getItems();
    const fabricObjectsInCanvas = this.page.fabricCanvas.getObjects();

    sortedPosterGraphicItems.sort((a, b) => {
      // TODO: See how an audio item will affect zIndex, especially when it's not loaded in high res.
      // return postermywall.Core.isPosterGenerating ? a.zIndex - b.zIndex : (fabricObjectsInCanvas.indexOf(a.fabricObject) - fabricObjectsInCanvas.indexOf(b.fabricObject));
      return fabricObjectsInCanvas.indexOf(a.fabricObject) - fabricObjectsInCanvas.indexOf(b.fabricObject);
    });

    return sortedPosterGraphicItems;
  };

  private getDelayFactorByTotalObjects = () => {
    if (this.page) {
      const totalGraphicItems = Object.keys(this.page.items.itemsHashMap).length;
      const totalObjectsWithDelay = totalGraphicItems - 1;
      const duration = this.animation ? this.animation.getDuration() : 0;

      return totalObjectsWithDelay > 0 ? (MAX_ITEM_ANIMATION_DELAY_FACTOR * duration) / totalObjectsWithDelay : 0;
    }
    return 0;
  };

  public setFrame = (frameTime: number) => {
    const itemsIds = Object.keys(this.graphicItemAnimationsHashMap);
    for (const itemId of itemsIds) {
      this.graphicItemAnimationsHashMap[itemId].setFrame(frameTime);
    }
  };
}
