import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import type {ImageBackgroundObject} from '@PosterWhiteboard/page/background/image-background.class';
import type {PosterObject} from '@PosterWhiteboard/poster/poster.types';
import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {PosterTypeObject} from '@PosterWhiteboard/poster-type/poster-type.types';

interface DimesionsAndCoordinates {
  width: number;
  height: number;
  x: number;
  y: number;
}

export class ResizePoster extends CommonMethods {
  public poster: Poster;

  constructor(poster: Poster) {
    super();
    this.poster = poster;
  }

  private resizePageItemsToTargetType(posterObject: PosterObject, pageUid: string, posterType: PosterTypeObject): void {
    const currentWidth = posterObject.width;
    const currentHeight = posterObject.height;
    const currentAspectRatio = currentWidth / currentHeight;

    const params = getParametersForResizing(posterType.width, posterType.height, currentAspectRatio);
    const scaleFactor = params.height / currentHeight;
    const resizeXAdjust = currentWidth - params.width;
    const resizeYAdjust = currentHeight - params.height;

    for (const [, itemObject] of Object.entries(posterObject.pages.pagesHashMap[pageUid].items.itemsHashMap)) {
      itemObject.x += Math.floor(params.x - (itemObject.x / currentWidth) * resizeXAdjust);
      itemObject.y += Math.floor(params.y - (itemObject.y / currentHeight) * resizeYAdjust);
      itemObject.scaleX *= scaleFactor;
      itemObject.scaleY *= scaleFactor;
    }
  }

  public getResizedPosterObjectForPosterType(posterType: PosterTypeObject): PosterObject {
    const updatedPoster = this.poster.toObject();

    const currentWidth = updatedPoster.width;
    const currentHeight = updatedPoster.height;

    if (!(currentWidth === posterType.width && currentHeight === posterType.height)) {
      for (const pageKey of Object.keys(updatedPoster.pages.pagesHashMap)) {
        const page = updatedPoster.pages.pagesHashMap[pageKey];
        this.resizePageItemsToTargetType(updatedPoster, pageKey, posterType);

        if (page.background.details.type === BackgroundTypeName.IMAGE) {
          const imageBackground = page.background.details;
          const bgDimsAndCoordsData = getBgDimsAndCoordsData(updatedPoster, posterType, imageBackground);

          if (!imageBackground.imageBackgroundItemObject.cropData) {
            imageBackground.imageBackgroundItemObject.cropData = {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
              imageWidth: 0,
              imageHeight: 0,
              cropped: true,
            };
          }
          imageBackground.imageBackgroundItemObject.cropData.x = bgDimsAndCoordsData.x;
          imageBackground.imageBackgroundItemObject.cropData.y = bgDimsAndCoordsData.y;
          imageBackground.imageBackgroundItemObject.cropData.width = bgDimsAndCoordsData.width;
          imageBackground.imageBackgroundItemObject.cropData.height = bgDimsAndCoordsData.height;
        }
      }

      updatedPoster.width = posterType.width;
      updatedPoster.height = posterType.height;
    }
    updatedPoster.type = posterType;
    return updatedPoster;
  }
}

const getParametersForResizing = (targetWidth: number, targetHeight: number, currentAspectRatio: number): DimesionsAndCoordinates => {
  let newHeight;
  let newWidth;

  newHeight = targetHeight;
  newWidth = targetHeight * currentAspectRatio;

  if (newWidth > targetWidth) {
    newWidth = targetWidth;
    newHeight = targetWidth / currentAspectRatio;
  }

  return {height: newHeight, width: newWidth, x: (targetWidth - newWidth) / 2, y: (targetHeight - newHeight) / 2};
};

const coordinatesAndDimensionsForBackground = (background: ImageBackgroundObject, targetAspectRatio: number): DimesionsAndCoordinates => {
  const backgroundRatio = background.imageBackgroundItemObject.cropData.width / background.imageBackgroundItemObject.cropData.height;
  const bgData = {} as DimesionsAndCoordinates;
  if (backgroundRatio < targetAspectRatio) {
    bgData.x = background.imageBackgroundItemObject.cropData.x;
    bgData.width = background.imageBackgroundItemObject.cropData.width;
    bgData.height = Math.floor(bgData.width / targetAspectRatio);
    bgData.y = background.imageBackgroundItemObject.cropData.y + (background.imageBackgroundItemObject.cropData.height - bgData.height) / 2;
  } else {
    bgData.y = background.imageBackgroundItemObject.cropData.y;
    bgData.height = background.imageBackgroundItemObject.cropData.height;
    bgData.width = Math.floor(bgData.height * targetAspectRatio);
    bgData.x = background.imageBackgroundItemObject.cropData.x + (background.imageBackgroundItemObject.cropData.width - bgData.width) / 2;
  }
  return bgData;
};

const getBgDimsAndCoordsData = (poster: PosterObject, posterType: PosterTypeObject, imageBackground: ImageBackgroundObject): DimesionsAndCoordinates => {
  const currentAspectRatio = poster.width / poster.height;
  const targetAspectRatio = posterType.width / posterType.height;

  let bgDimsAndCoordsData;
  if (currentAspectRatio < targetAspectRatio || (posterType.width === posterType.height && poster.width < poster.height)) {
    if (imageBackground.imageBackgroundItemObject.cropData) {
      bgDimsAndCoordsData = coordinatesAndDimensionsForBackground(imageBackground, targetAspectRatio);
    } else {
      bgDimsAndCoordsData = {
        width: imageBackground.imageBackgroundItemObject.width,
        height: Math.floor(imageBackground.imageBackgroundItemObject.width / targetAspectRatio),
        x: 0,
        y: (imageBackground.imageBackgroundItemObject.height - Math.floor(imageBackground.imageBackgroundItemObject.width / targetAspectRatio)) / 2,
      };
    }
  } else if (imageBackground.imageBackgroundItemObject.cropData) {
    bgDimsAndCoordsData = coordinatesAndDimensionsForBackground(imageBackground, targetAspectRatio);
  } else {
    bgDimsAndCoordsData = {
      width: Math.floor(imageBackground.imageBackgroundItemObject.height * targetAspectRatio),
      height: imageBackground.imageBackgroundItemObject.height,
      y: 0,
      x: (imageBackground.imageBackgroundItemObject.width - Math.floor(imageBackground.imageBackgroundItemObject.height * targetAspectRatio)) / 2,
    };
  }
  return bgDimsAndCoordsData;
};
