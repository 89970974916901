import type {BaseBackgroundObject} from '@PosterWhiteboard/page/background/background.class';
import {Background, BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ImageBackgroundItemObject} from '@PosterWhiteboard/page/background/image-background-item.class';
import {ImageBackgroundItem} from '@PosterWhiteboard/page/background/image-background-item.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {DeepPartial} from '@/global';

export interface ImageBackgroundObject extends BaseBackgroundObject {
  type: BackgroundTypeName.IMAGE;
  imageBackgroundItemObject: ImageBackgroundItemObject;
}

export class ImageBackground extends Background {
  public type: BackgroundTypeName.IMAGE = BackgroundTypeName.IMAGE;
  public imageBackgroundItem: ImageBackgroundItem;

  constructor(page: Page) {
    super(page);
    this.imageBackgroundItem = new ImageBackgroundItem(page);
  }

  public copyVals(obj: DeepPartial<ImageBackgroundObject>): void {
    const {imageBackgroundItemObject, ...itemObj} = obj;
    super.copyVals(itemObj);
    if (imageBackgroundItemObject) {
      this.imageBackgroundItem.copyVals(imageBackgroundItemObject);
    }
  }

  public toObject(): ImageBackgroundObject {
    return {
      type: this.type,
      imageBackgroundItemObject: this.imageBackgroundItem.toObject(),
    };
  }

  public async invalidate(): Promise<void> {
    await this.imageBackgroundItem.invalidate();
  }

  public async updateFromObject(
    imgBackgroundObj: DeepPartial<ImageBackgroundObject>,
    {updateRedux = true, undoable = true, doInvalidate = true, forceInit = false}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    const {imageBackgroundItemObject, ...obj} = imgBackgroundObj;
    this.copyVals(obj);
    if (imageBackgroundItemObject) {
      await this.imageBackgroundItem.updateFromObject(imageBackgroundItemObject, {updateRedux, undoable, doInvalidate, forceInit});
    }
  }

  public onBackgroundCreated(): void {
    this.imageBackgroundItem.page.setBackgroundImage(this.imageBackgroundItem.fabricObject);
  }

  public onRemove(): void {
    this.imageBackgroundItem.isUploadPollingInitialized = false;
    this.imageBackgroundItem.onRemove();
  }
}

export const createImageBackgroundFromObject = async (page: Page, opts: DeepPartial<ImageBackgroundItemObject>): Promise<ImageBackground> => {
  const background = new ImageBackground(page);
  await background.imageBackgroundItem.init(opts);
  return background;
};
